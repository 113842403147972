import store from '@/store';
import {
    mdiAccountCheckOutline,
    mdiAccountOutline,
    mdiAccountPlusOutline,
    mdiAccountRemoveOutline,
    mdiCogOutline,
    mdiDatabaseOutline,
    mdiDnsOutline,
    mdiPencilOutline,
} from '@mdi/js';
import { ref, watch } from '@vue/composition-api';
import _ from 'lodash';
import { i18n } from '@/plugins/i18n';

export default function useEntitiesList() {
    const faqListTable = ref([]);

    const tableColumns = [
        { text: i18n.t('Question'), value: 'question', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('Answer'), value: 'answer', class: 'text-uppercase' },
        { text: i18n.t('Category'), value: 'category', class: 'text-uppercase' },
        {
            text: '',
            value: 'actions',
            width: '5%',
        },
    ];

    const searchQuery = ref('');
    const roleFilter = ref(null);
    const planFilter = ref(null);
    const statusFilter = ref(null);
    const totalFaqListTable = ref(0);
    const loading = ref(false);
    const options = ref({
        sortBy: ['id'],
        sortDesc: [true],
    });
    const faqTotalLocal = ref([]);
    const selectedRows = ref([]);
    const oldSearch = ref('');
    const oldCategoryFilter = ref(' ');

    // fetch data
    const fetchFaqList = () => {
        let verifyQuery = store.getters['app-faq/getFaqQuery'];
        let isDeleteFaq = store.getters['app-faq/getIsDeleteFaq'];
        store
            .dispatch('app-faq/fetchFaqList', {})
            .then((response) => {
                if (isDeleteFaq) {
                    faqListTable.value = [];
                    faqListTable.value.push(...response.data.data);
                    store.commit('app-faq/setIsDeleteFaq', false);
                } else if (
                    oldSearch.value[0] == verifyQuery.filters.$or[0].question.$containsi &&
                    oldSearch.value[1] == verifyQuery.filters.$or[1].answer.$containsi &&
                    oldCategoryFilter.value === verifyQuery.filters.category.$containsi
                ) {
                    faqListTable.value = [];
                    faqListTable.value.push(...response.data.data);
                } else {
                    oldSearch.value[0] == verifyQuery.filters.$or[0].question.$containsi;
                    oldSearch.value[1] == verifyQuery.filters.$or[1].answer.$containsi;
                    oldCategoryFilter.value = verifyQuery.filters.category.$containsi;
                    faqListTable.value = [];
                    faqListTable.value.push(...response.data.data);
                    faqListTable.value.forEach((element) => {
                        if (element.category == 'General') {
                            element.category = 'Geral';
                        } else if (element.category == 'Support') {
                            element.category = 'Suporte';
                        } else if (element.category == 'Ticket') {
                            element.category = 'Bilhética';
                        }
                    });
                }
                totalFaqListTable.value = response.data.meta.pagination.total;
                // faqTotalLocal.value = faqTotal;

                // remove loading state
                loading.value = false;
            })
            .catch((error) => {
                throw error;
            });
    };

    watch([searchQuery, roleFilter, planFilter, statusFilter, options], () => {
        loading.value = true;
        selectedRows.value = [];
        fetchFaqList();
    });

    const resolveFaqRoleVariant = (role) => {
        if (role === 'subscriber') return 'primary';
        if (role === 'author') return 'warning';
        if (role === 'maintainer') return 'success';
        if (role === 'editor') return 'info';
        if (role === 'admin') return 'error';

        return 'primary';
    };

    const resolveFaqRoleIcon = (role) => {
        if (role === 'subscriber') return mdiAccountOutline;
        if (role === 'author') return mdiCogOutline;
        if (role === 'maintainer') return mdiDatabaseOutline;
        if (role === 'editor') return mdiPencilOutline;
        if (role === 'admin') return mdiDnsOutline;

        return mdiAccountOutline;
    };

    const resolveEntitiestatusVariant = (status) => {
        if (status === 'pending') return 'warning';
        if (status === 'active') return 'success';
        if (status === 'inactive') return 'secondary';

        return 'primary';
    };

    const resolveFaqTotalIcon = (total) => {
        if (total === 'Total Entities') return { icon: mdiAccountOutline, color: 'primary' };
        if (total === 'Paid Entities') return { icon: mdiAccountPlusOutline, color: 'error' };
        if (total === 'Active Entities') return { icon: mdiAccountCheckOutline, color: 'success' };
        if (total === 'Pending Entities') return { icon: mdiAccountRemoveOutline, color: 'warning' };

        return { icon: mdiAccountOutline, color: 'primary' };
    };

    const limitFaqText = (entities) => {
        if (entities.length > 1) {
            return ' + ' + (entities.length - 1);
        }
    };

    return {
        faqListTable,
        tableColumns,
        searchQuery,
        roleFilter,
        planFilter,
        statusFilter,
        totalFaqListTable,
        loading,
        options,
        faqTotalLocal,
        selectedRows,
        fetchFaqList,
        resolveFaqRoleVariant,
        resolveFaqRoleIcon,
        resolveEntitiestatusVariant,
        limitFaqText,
    };
}
